// COLORS
$primary-color: #7EA3CC;
$secondary-color: #7EA3CC;

$white-color: #1C2541;

$dark-grey: #1C2541;
$light-grey: #820000;

$dark-blue-text: #ffffff; // For Headings

// FONT SIZE
$default-font-size: 1.6rem;
$big-font-size: 4rem;
$mid-font-size: 2.5rem;
